import React, {useState,useEffect} from "react";
import Draggable from "react-draggable";
import LazyLoad from 'react-lazyload';
import ReactModal from "react-modal"

export default function Image(props) {
// console.log(props.column)
    //    const [isOpen, setOpen] = useState(false);
// function getMeta(url, callback) {

    // img.onload =  callback(this.width, this.height) }
// function handleOpenModal() {
//     setOpen(true);
// }
// function handleCloseModal() {
//     setOpen(false);
// }
// function onImgLoad({target:img}) {
//     if (img.offsetHeight > img.offsetWidth) {
// setWidth(10)
//     }
//     else {
//         setWidth(7)
//     }
    
// }
// let width, height = getMeta(
//   url,
//   function(width, height) { return {width, height} }
// );
    return (
        <>
          <LazyLoad once>
    <Draggable>
   <img
                      className="image"
                      draggable="false"
                    //   onClick={handleOpenModal}
                      src={props.url}
                    //   onLoad={onImgLoad}      
                      style={{top: `calc(${props.row} * 15vw)`, left: `calc(${props.column} * 14vw + 5vw)`, width: `13vw` }}
                    />

     
    </Draggable>
       </LazyLoad>
        {/* <ReactModal 
           isOpen={isOpen}
           zIndex = {100}
           contentLabel="Minimal Modal Example"
         
        >
          <button onClick={handleCloseModal}>Close Modal</button>
        </ReactModal> */}
       </>
    
    )
}

import React, {useState,useEffect} from "react";
import Draggable from "react-draggable";
import LazyLoad from 'react-lazyload';


export default function Text(props) {

    return (
         
    <Draggable>
  <div
                      className="text-block"
                      style={{ top: `calc(${props.row} * 15vw)` ,left: `calc(${props.column} * 14vw  + 5vw )`, width: `13vw` }}
                    >
                        <div style={{padding: "1rem"}}>
                        {props.content}
                    
                      </div>
                    </div>
     
    </Draggable>
   
    
    )
}

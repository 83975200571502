import React, { useState, useEffect } from "react";
import Draggable from "react-draggable"; // The default
import Image from "./Image";
import Link from "./Link"
import Text from "./Text";
export default function Loader() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://api.are.na/v2/channels/spatial-library")
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setData(result);
          setIsLoaded(true);
        },

        (error) => {
          setError(error);
          setIsLoaded(true);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    console.log(data.contents);
    return (
      <>
        <div className="top-container">
          <div className="content">
            <Draggable>
              <h3 style={{ top: "-100px", left: "5vw", position: "absolute" }}>
                {data.title}
              </h3>
            </Draggable>
            <div className="images">
              {data.contents.map((item, n) => {
                switch (item.class) {
                  case "Image":
                    return (
                      <Image
                        url={item.image.display.url}
                        column={n % 6}
                        row={Math.floor(n / 6)}
                      />
                    );
                  case "Text":
                    return (
                      <Text
                        content={item.content}
                        column={n % 6}
                        row={Math.floor(n / 6)}
                      />
                    );
                  case "Link":
                    return (
                      <Link
                        content={item.content}
                        title={item.title}
                        url = {item.source.url}
                        imgUrl= {item.image.display.url}
                        column={n % 6}
                        row={Math.floor(n / 6)}
                      />
                    );
                  default:
                    return null;
                }
              })}
              
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React, { useState, useEffect } from "react";
import "./styles.css";
import Draggable from "react-draggable"; // The default
import CanvasDraw from "react-canvas-draw";
import Loader from "./loader";
import clear from './images/clear.svg'
import eraser from './images/eraser.svg'
import pencil from './images/pencil.svg'
import text from "./images/text.svg"
export default function Main() {
  const [blank, setBlank] = useState(false);

  function handleBlank() {
    setBlank(!blank);
  }
  return (
    <>
      {/* {!blank ? (
        <button className="button" onClick={() => handleBlank()}>
          blank canvas
        </button>
      ) : (
        <button className="button" onClick={() => handleBlank()}>
          collected canvas
        </button>
      )} */}
      {blank ? (
        <CanvasDraw
          lazyRadius="0"
          brushRadius="3"
          brushColor="#ddd"
          canvasHeight="100vh"
          canvasWidth="100vw"
          style={{ zIndex: "1" }}
        />
      ) : (
        <>
       
            <div style={{ position: "absolute", left: "4.5vw" }}>
              <div className="header-wrapper">
              <div className="header">
                Garrett Vercoe
     
              </div>
                         <div className="subtitle">Internet Desktop</div>
            

             
                </div>
            </div>
   
    
            <div style={{ position: "absolute", left: "37.5vw", bottom: "4.5vw" }}>
              <div className="nav-wrapper">

              <div className="tool brush"><img src={pencil} alt="icon" /></div>
              <div className="tool eraser"><img src={eraser} alt="icon" /></div>
              <div className="tool text"><img src={text} alt="icon" /></div>
                <div className="tool clear"><img src={clear} alt="icon" /></div>
              <div className="folder">Folder name</div>
             
                </div>
            </div>
 

          <div
            style={{
              zIndex: "-100",
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          >
            <CanvasDraw
              lazyRadius="0"
              brushRadius="3"
              catenaryColor="#000"
              gridColor="#dddfe4"
              brushColor="#ddd"
              canvasHeight="100%"
              backgroundColor = "transparent"
              canvasWidth="100%"
              style={{ zIndex: "-1000" }}
            />
          </div>
          {/* <Draggable>
            <div style={{ position: "absolute", left: "800px", top: "200px" }}>
              <h6 style={{ position: "absolute", transform: "rotate(-20deg)" }}>
                hello@garrettvercoe.com
              </h6>
            </div>
          </Draggable>

          <Draggable>
            <div style={{ position: "absolute", left: "400px", top: "100px" }}>
              <h6 style={{ position: "absolute", transform: "rotate(-20deg)" }}>
                An Internet Desktop
              </h6>
            </div>
          </Draggable> */}

          <Loader />
        </>
      )}
    </>
  );
}

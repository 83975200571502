import React, {useState,useEffect} from "react";
import Draggable from "react-draggable";
import LazyLoad from 'react-lazyload';


export default function Link(props) {

    return (
         
    <Draggable>
  <div
                      className="text-block"
                      style={{ top: `calc(${props.row} * 15vw)` ,left: `calc(${props.column} * 14vw  + 5vw )`, width: `13vw` }}
                    >
                          <img
                      className="link-image"
                      draggable="false"
                    //   onClick={handleOpenModal}
                      src={props.imgUrl}
                    //   onLoad={onImgLoad}      
                                          style={{ width: `100%`, display: "initial" }}
                    />
                        <div style={{padding: "1rem"}}>
                       <a className="link" target="_blank" href={`${props.url}`}>{props.title}</a> 
                    
                      </div>
                    </div>
     
    </Draggable>
   
    
    )
}
